<i18n>
{
  "en": {
    "browser_title": "Categories",
    "page-title": "Categories"
  },
  "ja": {
    "browser_title": "カテゴリー",
    "page-title": "カテゴリー"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div id="category"  class="page-margin">
      <div class="block-header">
        <div class="container">
          <h1>{{ $t('page-title') }}</h1>
        </div>
      </div>

      <category-list />
    </div><!-- /#category -->
  </main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import CategoryList from '@/components/category/categoryList.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'category-list': CategoryList,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_category.scss';
</style>
